import {TmpAttachment} from '@ozark/functions/src/functions/express/private/types/Attachments';
import firebase from 'firebase/compat/app';
import {useState} from 'react';
import {v4 as uuidV4} from 'uuid';
import {tmpDirName} from '../../common';
import {Firebase} from '../firebase';

import {getFileNameWithoutExt, rename, UploadDocument} from '../util';

export enum FileStatus {
  pending = 'pending',
  processing = 'processing',
  completed = 'completed',
  error = 'error',
}

export interface FileState {
  filename: string;
  oldFilename: string;
  status: FileStatus;
  progress?: number;
  error?: string;
}

export interface FileToUpload {
  file: File;
  folderName: string | null;
  uploadDocument: UploadDocument | null;
  state: FileState;
}

export interface UseAttachmentsUploadProps {
  token?: string;
  onProgressChange?: (index: number, newFileState: FileState) => void;
  onError?: (message: string) => void;
}

interface UploadFilesSuccess {
  success: true;
  attachments: Array<TmpAttachment>;
}

interface UploadFilesError {
  success: false;
}

type UploadFilesResult = UploadFilesSuccess | UploadFilesError;

const getTmpAttachment = (fileToUpload: FileToUpload): TmpAttachment => {
  if (fileToUpload.state.status !== 'completed') {
    throw new Error('Only uploaded files should be transformed into attachments');
  }

  const fileState = fileToUpload.state;

  return {
    name: fileToUpload.uploadDocument ?? getFileNameWithoutExt(fileToUpload.file),
    filename: fileState.filename,
    oldFilename: fileState.oldFilename,
    createdAt: new Date(),
    folderName: fileToUpload.folderName ?? null,
    label: getFileNameWithoutExt(fileToUpload.file),
  };
};

export function useAttachmentsUpload(props: UseAttachmentsUploadProps = {}) {
  const [isUploading, setIsUploading] = useState(false);

  const {onProgressChange, onError, token} = props;

  const handleProgressChange = (index: number, newFileState: FileState) => {
    if (!onProgressChange || typeof onProgressChange !== 'function') {
      return;
    }

    onProgressChange(index, newFileState);
  };

  const handleError = (message: string) => {
    if (!onError || typeof onError !== 'function') {
      return;
    }

    onError(message);
  };

  const uploadFile = async (index: number, fileToUpload: FileToUpload): Promise<TmpAttachment> => {
    const prefixName = fileToUpload.uploadDocument ?? getFileNameWithoutExt(fileToUpload.file);
    const fileData: File = rename(fileToUpload.file, `${prefixName}-${uuidV4()}`);

    const cloudTmpPath = `${tmpDirName}/${fileData.name}`;

    if (token) {
      await Firebase.auth.signInWithCustomToken(token);
    }

    const uploadTask = Firebase.storage.ref(cloudTmpPath).put(fileData);

    const fileState: FileState = {
      ...fileToUpload.state,
      filename: fileData.name,
    };

    return new Promise((resolve, reject) => {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot: any) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          handleProgressChange(index, {
            ...fileState,
            status: FileStatus.processing,
            progress,
          });
        },
        (err: any) => {
          handleProgressChange(index, {
            ...fileState,
            status: FileStatus.error,
            error: String(err),
          });
          reject(
            `An error occured while uploading file ${fileState.oldFilename}. Please try again.`
          );
        },
        async () => {
          const fileRef = Firebase.storage.ref(`${tmpDirName}/`).child(fileData.name);

          if (token) {
            await Firebase.auth.signInWithCustomToken(token);
          }

          const size = (await fileRef.getMetadata()).size;
          if (size <= 0) {
            handleProgressChange(index, {
              ...fileState,
              status: FileStatus.error,
              error: `This file was not uploaded correctly. Reupload it to try again.`,
            });
            return reject(
              `An error occured while uploading file ${fileState.oldFilename}. Please try again.`
            );
          }

          const completedFileState: FileState = {
            ...fileState,
            status: FileStatus.completed,
          };

          handleProgressChange(index, completedFileState);

          resolve(getTmpAttachment({...fileToUpload, state: completedFileState}));
        }
      );
    });
  };

  const uploadFiles = async (filesToUpload: Array<FileToUpload>): Promise<UploadFilesResult> => {
    setIsUploading(true);

    const tmpAttachments: Array<TmpAttachment> = [];
    try {
      for (const [index, fileToUpload] of filesToUpload.entries()) {
        let result: TmpAttachment;

        if (fileToUpload.state.status === 'completed') {
          result = getTmpAttachment(fileToUpload);
        } else {
          result = await uploadFile(index, fileToUpload);
        }

        tmpAttachments.push(result);
      }
    } catch (err) {
      handleError(String(err));

      return {
        success: false,
      };
    } finally {
      setIsUploading(false);
    }

    return {
      success: true,
      attachments: tmpAttachments,
    };
  };

  return {
    isUploading,
    uploadFiles,
  };
}
